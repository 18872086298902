<template>
  <div class="p-4">
    <a-button
      class="float-right mb-3 d-flex align-items-center"
      type="primary"
      @click="handleShowForm"
    >
      <template #icon><i class="bi bi-plus mr-1 mb-1"></i></template>
      Add New
    </a-button>

    <organization-list-new @onEditClick="handleEdit" />
    <a-modal
      v-model:visible="showForm"
      centered
      :closable="false"
      :title="(isEdit === true ? 'Update' : 'Add') + ' Organization'"
      :footer="null"
      destroyOnClose
    >
      <add-new-organization
        @cancel="handleShowForm"
        :initialValues="formValues"
        :edit="isEdit"
        @resetFormValues="resetFormValues"
      >
      </add-new-organization>
    </a-modal>
  </div>
</template>
<script>
import AddNewOrganization from './AddNewOrganization.vue';
import OrganizationListNew from './OrganizationList.vue';

export default {
  components: {
    AddNewOrganization,
    OrganizationListNew,
  },
  data() {
    return {
      showForm: false,
      isEdit: false,
      formValues: {
        name: '',
        address: '',
        employee: '',
        desc: '',
        fps_cap: '',
      },
    };
  },
  methods: {
    handleShowForm() {
      this.showForm = !this.showForm;
      if (this.isEdit) {
        this.isEdit = false;
      }
    },
    handleEdit(rowData) {
      this.handleShowForm();
      this.isEdit = true;
      this.formValues = {
        name: rowData.Org_name,
        address: rowData.Address,
        employee: rowData.Number_of_emp,
        desc: rowData.Description,
        fps_cap: rowData.fps_cap,
      };
    },
    resetFormValues() {
      this.formValues = {
        name: '',
        address: '',
        employee: '',
        desc: '',
        fps_cap: ''
      };
    },
  },
};
</script>
